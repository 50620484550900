import styled from "styled-components";

export const Photo = styled.div`
  margin-top: 20px;

  img {
    width: 100%;
    max-width: 510px;
  }
`;
